/*
 * @Description: 扶持结算列表
 */

import { get,postJSON } from "@/utils/request"

export function pageQuery(data) {
    return get('/system/rebate/list', data)
}
export function findById(data) {
    return get('/system/rebate/' + data)
}
// 提现
export function withdrawRebate(data) {
    return postJSON('/system/rebate/withdraw',data)
}